
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import cover from "../../images/blobmaker-cover.jpg"

function SEO({
  description = "Make organic SVG shapes for your next design. Modify the complexity, contrast, and color, to generate unique SVG blobs every time.",
  lang = "en",
  meta = [],
  title = "Blobmaker - Make organic SVG shapes for your next design",
  image = cover,
}) {

  const url = "https://www.blobmaker.app"
  const blobmakerCover = url + image

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: blobmakerCover,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@zcreativelabs`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: blobmakerCover,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={url} />
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
